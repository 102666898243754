// 添加资源加载错误监听
let resourcesFailedToLoad = false
const resourceLoadErrors: string[] = []

// 添加错误处理状态标志
let isHandlingError = false

// 添加初始化状态追踪
let isInitialized = false

// 获取当前语言参数
function getCurrentLang(): string {
  try {
    const url = window.location.href
    // 获取 ? 后面的所有内容，包括 hash
    const queryIndex = url.indexOf('?')
    if (queryIndex === -1) {
      return 'en'
    }

    const queryString = url.substring(queryIndex + 1)
    // 处理带有 hash 的 URL，只取 # 之前的部分
    const hashIndex = queryString.indexOf('#')
    const finalQueryString = hashIndex === -1 ? queryString : queryString.substring(0, hashIndex)

    const params = new URLSearchParams(finalQueryString)
    const lang = params.get('lang')?.toLowerCase()

    return lang === 'zh-cn' || lang === 'zh-CN' ? 'zh-CN' : 'en'
  } catch {
    return 'en'
  }
}

// 简化消息配置
const messages = {
  'zh-CN': {
    errorText: '抱歉，加载失败了～',
    retryText: '点此重试'
  },
  en: {
    errorText: 'Sorry, loading failed~',
    retryText: 'Click to retry'
  }
}

// 获取翻译文本
function t(key: string): string {
  const currentLang = getCurrentLang()
  return messages[currentLang]?.[key] || messages['en'][key] || key
}

// 简化错误处理UI展示
function handleEarlyError() {
  if (isHandlingError) return
  isHandlingError = true
  const appElement = document.querySelector('#app')
  if (appElement) {
    appElement.innerHTML = `
      <div style="
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
      ">
        <span style="
          color: #666;
          font-size: 14px;
        ">${t('errorText')}</span>
        <a href="javascript:void(0);" 
           onclick="location.reload()" 
           style="
             color: #409eff;
             margin-left: 8px;
             text-decoration: none;
             cursor: pointer;
           ">${t('retryText')}</a>
      </div>
    `
  }
  removeLoading()
}

// 统一的错误处理监听器
window.addEventListener(
  'error',
  (event: any) => {
    // 网络错误处理
    if (
      event.error?.message?.includes('net::ERR_ADDRESS_UNREACHABLE') ||
      event.message?.includes('net::ERR_ADDRESS_UNREACHABLE')
    ) {
      handleEarlyError()
      event.preventDefault()
      resourcesFailedToLoad = true
      console.error(`${t('resourceError')}: Network unreachable`)
      return
    }

    // 资源加载错误处理
    if (event.target) {
      let resourceType = ''
      let resource = ''

      if (event.target instanceof HTMLScriptElement) {
        resourceType = 'JavaScript'
        resource = event.target.src
      } else if (event.target instanceof HTMLLinkElement && event.target.rel === 'stylesheet') {
        resourceType = 'CSS'
        resource = event.target.href
      } else if (event.target instanceof HTMLImageElement) {
        resourceType = 'Image'
        resource = event.target.src
      }

      if (resource) {
        handleEarlyError()
        event.preventDefault()
        resourcesFailedToLoad = true
        resourceLoadErrors.push(`[${resourceType}] ${resource}`)
        console.error(`${t('resourceError')} (${resourceType}): ${resource}`)
      }
    }
  },
  true
)

// 添加 Promise 错误处理
window.addEventListener('unhandledrejection', (event) => {
  if (event.reason?.message?.includes('net::ERR_ADDRESS_UNREACHABLE')) {
    handleEarlyError()
    event.preventDefault()
    resourcesFailedToLoad = true
  }
})

import 'vue/jsx'

// 引入windi css
import '@/plugins/unocss'

// 导入全局的svg图标
import '@/plugins/svgIcon'

// 初始化多语言
import { setupI18n } from '@/plugins/vueI18n'

// 引入状态管理
import { setupStore } from '@/store'

// 全局组件
import { setupGlobCom } from '@/components'

// 引入element-plus
import { setupElementPlus } from '@/plugins/elementPlus'

// 引入全局样式
import '@/styles/index.less'

// 引入动画
import '@/plugins/animate.css'

// 路由
import { setupRouter } from './router'

// 权限
import { setupPermission } from './directives'

import { createApp } from 'vue'

import App from './App.vue'

import './permission'

// 创建实例
const setupAll = async () => {
  try {
    const app = createApp(App)
    await setupI18n(app)
    setupStore(app)
    setupGlobCom(app)
    setupElementPlus(app)
    setupRouter(app)
    setupPermission(app)
    app.mount('#app')
    removeLoading()
    isInitialized = true
    return true
  } catch (error) {
    console.error(t('initError'), getErrorMessage(error))
    handleInitializationFailure()
    return false
  }
}

// 移除loading动画
function removeLoading() {
  const loadingElement = document.querySelector('.app-loading')
  if (loadingElement) {
    loadingElement.remove()
  }
}

// 使用相同的错误UI处理初始化失败
function handleInitializationFailure() {
  if (isHandlingError) return
  handleEarlyError()
}

// 添加超时机制，确保不会无限等待
const TIMEOUT_DURATION = 15000 // 15秒超时

// 设置超时计时器
let timeoutId = setTimeout(() => {
  if (!isInitialized) {
    console.error(t('timeout'))
    if (resourcesFailedToLoad) {
      console.error(t('resourceError') + ':', resourceLoadErrors)
    }
    handleInitializationFailure()
  }
}, TIMEOUT_DURATION)

// 添加清理函数
function cleanup() {
  clearTimeout(timeoutId)
  isHandlingError = false
  resourcesFailedToLoad = false
  resourceLoadErrors.length = 0
  isInitialized = false
}

// 添加页面卸载时的清理
window.addEventListener('unload', cleanup)

// 提取 load 事件处理为命名函数
function handleLoadComplete() {
  setTimeout(() => {
    if (!isHandlingError && document.querySelector('.app-loading')) {
      console.warn(t('loadingStuck'))
      handleInitializationFailure()
    }
  }, 1000)
}

window.addEventListener('load', handleLoadComplete)

// 修改 setupAll 成功处理
setupAll()
  .then((success) => {
    if (success) {
      cleanup()
    }
  })
  .catch((error) => {
    clearTimeout(timeoutId)
    console.error(t('initError') + ':', error)
    handleInitializationFailure()
  })

// 修改错误信息展示逻辑
function getErrorMessage(error: any): string {
  if (typeof error === 'string') return error
  if (error instanceof Error) return error.message
  return t('errorDesc')
}
